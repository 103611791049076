<template>
  <div class="homeIndex" style="height: 100vh;">
    <div class="mouse-wheel-slide-vertical">
      <div class="slide-container">
        <div class="slide-wrapper" ref="slide">
          <div class="slide-content">
            <div class="slide-page" id="scrolltt">
              <div
                class="flex-col"
                style="width: 100%; height: 100%"
              >
                <TopSwiper></TopSwiper>
              </div>
            </div>
            <div class="slide-page">
              <div class="nineSystem flex-col ali-center jus-center">
                <div class="commonTitle">9大应用场景</div>
                <!-- <div class="info24">9 APPLICATION SCENARIOS</div> -->
                <div class="flex-col nineSystemContent">
                  <div class="flex-row jus-between margin-bottom-17">
                    <div class="box11 flex-col" @click="toSolutePlan(1)">
                      <img
                        class="pic4"
                        referrerpolicy="no-referrer"
                        :src="num>2?  require('@/assets/index/img3.png'):''"
                      />
                      <div class="mod4 flex-col">
                        <span class="word30">01&nbsp;线下健康科普基地</span>
                      </div>
                    </div>

                    <div style="flex: 1" class="flex-col jus-between">
                      <div class="flex-row jus-around">
                        <div class="box11 flex-col mod5" @click="toSolutePlan(2)">
                          <img :src="num>2?  require('@/assets/index/img4.png'):''"/>
                          <div class="mod4 flex-col">
                            <span class="word30">02&nbsp;线上健康教育平台</span>
                          </div>
                        </div>
                        <div class="box11 flex-col mod7" @click="toSolutePlan(4)">
                          <img :src="num>2?  require('@/assets/index/img5.png'):''"/>
                          <div class="mod4 flex-col">
                            <span class="word30">04&nbsp;健康社区</span>
                          </div>
                        </div>
                      </div>
                      <div class="flex-row jus-around">
                        <div class="box11 flex-col mod6" @click="toSolutePlan(3)">
                          <img :src="num>2?  require('@/assets/index/img6.png'):''"/>
                          <div class="mod4 flex-col">
                            <span class="word30">03&nbsp;健康管理干预服务</span>
                          </div>
                        </div>
                        <div class="box11 flex-col bd2" @click="toSolutePlan(5)">
                          <img :src="num>2?  require('@/assets/index/img7.png'):''"/>
                          <div class="mod4 flex-col">
                            <span class="word30">05&nbsp;健康家庭</span>
                          </div>
                        </div>

                        <div class="box11 flex-col bd4" @click="toSolutePlan(6)">
                          <img :src="num>2?  require('@/assets/index/img8.png'):''"/>
                          <div class="mod4 flex-col">
                            <span class="word30">06&nbsp;健康校园</span>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="box11 flex-col" @click="toSolutePlan(7)">
                      <img :src="num>2?  require('@/assets/index/img9.png'):''" alt=""/>
                      <div class="mod11 flex-col">
                        <span class="word30">07&nbsp;健康企业</span>
                      </div>
                    </div>
                  </div>
                  <div class="flex-row jus-between">
                    <div class="box11 flex-col box16" @click="toSolutePlan(8)">
                      <img :src="num>2?  require('@/assets/index/img10.png'):''"/>
                      <div class="mod4 flex-col">
                        <span class="word30">08&nbsp;健康乡村</span>
                      </div>
                    </div>

                    <div class="box11 flex-col box18" @click="toSolutePlan(9)">
                      <img :src="num>2?  require('@/assets/index/img11.png'):''"/>
                      <div class="mod4 flex-col">
                        <span class="word30">09&nbsp;健康公园</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="slide-page">

              <div class="projects flex-col asm">
                <div class="word16 flex-row ali-center jus-center">
                  <div class="commonTitle">经典案例</div>
                </div>
                <!-- <div class="word17">CLASSIC CASE</div> -->
                <img class="pic1" :src="num>3? require('@/assets/index/img12(2).jpg'):'' "/>

                <carousel-3d
                  class="carousel-3d"

                  v-if="slides.length>0"
                  :perspective="20"
                  width="480"
                  height="690"
                  :space="500"
                  :scaling="50"
                >
                  <slide v-for="(slide, i) in slides" :index="i" :key="i">
                    <div class="slideItems flex-col">
                      <img :src="num>3?'http://101.35.0.207:8888/'+slide.coverImg:''" alt=""/>
                      <div class="info11">{{ slide.title }}</div>
                      <div class="paragraph2" v-html="slide.content"></div>
                      <!-- <div class="main4 flex-row jus-center">
                        <span class="word19">MORE</span>
                        <img
                          class="label10"
                          src="@/assets/product-display/icon1.png"
                        />
                      </div> -->
                    </div>
                  </slide>
                </carousel-3d>
              </div>
            </div>
            <Bottom></Bottom>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import TopSwiper from "../components/TopSwiper.vue";
  import Bottom from "../components/Bottom.vue";
  import BScroll from "@better-scroll/core";
  import MouseWheel from "@better-scroll/mouse-wheel";


  BScroll.use(MouseWheel);
  import Slide from "@better-scroll/slide";

  BScroll.use(Slide);
  import {getGwSyList} from "@/api/officialCases/officialCases.js";
  // import {throttle} from "../components/Tool/throttle.js"
  var load = require('lodash');
  export default {
    name: "HomeIndex",
    components: {TopSwiper, Bottom},
    data() {
      return {
        currentIndex: 1,
        currentPageIndex: 0,
        slides: [],
        videoDom: null,
        num: 0,
        getData: null,
        text: 'aa',
        webSocketCloseFlase: false,
        firstConect:true,
        userfrom:null,//用户来源

      };

    },
    created() {

      this.slide = null;
      var a = {subsystemIdentificationCode:'1E1FF846F78548E6AC29076215F10CDA'}
      getGwSyList(a).then((response) => {
        this.slides = response.data

      })
      this.num = parseInt(window.sessionStorage.getItem("num"))

      console.log("-------------------------进入首页了----------------------------");


    },
    mounted() {
      this.init()
      this.getBrowserType()
      this.flag = setInterval(() => {
        this.num++
      }, 1000)
       this.userfrom = window.sessionStorage.getItem("from")
      this.$mybus.emit('userBehavior', {from:this.userfrom,moduleLevel1:1,moduleLevel2:1})

    },
    beforeDestroy() {
      this.slide.destroy();
      window.sessionStorage.setItem("num", 4)
    },

    watch: {
      num(newa) {
        if (newa == 4) {
          clearInterval(this.flag)
        }
      }

    },
    computed: {
      fmousewheelEvent() {
        return load.throttle(this.MouseWheelStart, 500, {leading: true, trailing: false})
      }
    },
    methods: {
      getBrowserType() {
        var mousewheelevt = (/Firefox/i.test(navigator.userAgent)) ? "DOMMouseScroll" : "mousewheel"

        //判断是否是IE
        if (document.attachEvent) {
          document.attachEvent("on" + mousewheelevt, function (e) {
            this.fmousewheelEvent(e, e.wheelDelta);
          });
        }
        //FireFox(DOMMouseScroll)、Chrome、Opera、safari
        else if (document.addEventListener) {
          var this_ = this
          document.addEventListener(mousewheelevt, function (e) {
            e = e || window.event;
            if (e.detail) {//Firefox
              //判断浏览器

              this_.fmousewheelEvent(e, e.detail * 40);

            }
            else if (e.wheelDelta) {
              //判断浏览器
              this_.fmousewheelEvent(e, -e.wheelDelta);

            }
          }, false);
        }
      },
      init() {
        this.slide = new BScroll(this.$refs.slide, {
          scrollX: false,
          scrollY: true,
          slide: {
            loop: false,
            threshold: 200,
            autoplay: false,
            easing: {
              style: 'cubic-bezier(.47,0,.74,.71)'
            }
          },
          click: true,
          momentumLimitDistance: 1000,
          preventDefault: false,
          swipeTime: 1000,
          probeType: 3,
          mouseWheel: false,
          bounce: true,
          momentum: false,
        });
      },
      MouseWheelStart(e, deltaY) {
        this.mouseWheelStart(e, deltaY)

        if(this.$route.path == '/home-index') {

          let cur = this.currentIndex
          if (deltaY > 0) {
            cur += 1
          } else {
            cur -= 1
          }
          if (cur >= 4) {
            cur = 4
          }
          if (cur < 1) {
            cur = 1
          }
          this.currentIndex = cur
          this.$mybus.emit('userBehavior', {from: this.userfrom, moduleLevel1: 1, moduleLevel2: cur})
        }

      },
      toSolutePlan(id) {
        this.$router.push({name: 'solutePlan', params: {id}})
      },
      mouseWheelStart(e, deltaY) {

        if(this.$route.path == '/home-index'){

          let currentPageIndex = this.currentPageIndex

          // this.websocketsend({"module_level_1":1,"module_level_2":this.currentPageIndex+1})
          let dir = deltaY
          let b = document.getElementsByClassName("slide-page")
          let a = document.querySelector(".section12")

          if (this.currentPageIndex == 2 && dir > 0) {
            this.slide.scrollTo(0, -(b[this.currentPageIndex].offsetTop) - a.offsetHeight, 1500);
            this.toBottom = true;
            return;
          }
          if (this.toBottom == true && dir < 0) {
            this.currentPageIndex = 2
            this.slide.scrollTo(0, -(b[this.currentPageIndex].offsetTop), 1500);
            // this.scrollToAnimation(a.offsetTop,b[this.currentPageIndex].offsetTop-10,500)
            this.toBottom = false;
            return;
          }


          if (dir > 0)
           currentPageIndex += 1
          else
            currentPageIndex -= 1
          if (currentPageIndex <= 0)
            currentPageIndex = 0
          if (currentPageIndex >= 2)
            currentPageIndex = 2
          if (currentPageIndex >= 0 && currentPageIndex <= 2)
            this.slide.goToPage(0, currentPageIndex, 700)
          this.currentPageIndex = currentPageIndex
        }

      },

    },


  }


</script>

<style lang="stylus" rel="stylesheet/stylus" scoped>


  .right-translateX {
    &-enter-active, &-leave-active {
      transition: all 0.5s ease;
    }

    &-enter, &-leave-to {
      transform: translateX(100px);
      opacity: 1;
    }
  }

  .left-translateX {
    &-enter-active, &-leave-active {
      transition: all 0.5s ease;
    }

    &-enter, &-leave-to {
      transform: translateX(-100px);
      opacity: 1;
    }
  }

  .top-translateY-1 {
    &-enter-active, &-leave-active {
      transition: all 0.8s ease;
    }

    &-enter, &-leave-to {
      transform: translateY(80px);
      opacity: 1;
    }
  }

  .top-translateY-2 {
    &-enter-active, &-leave-active {
      transition: all 0.8s ease .1s;
    }

    &-enter, &-leave-to {
      transform: translateY(80px);
      opacity: 1;
    }
  }

  .top-translateY-3 {
    &-enter-active, &-leave-active {
      transition: all 0.8s ease .2s;
    }

    &-enter, &-leave-to {
      transform: translateY(80px);
      opacity: 1;
    }
  }

  .homeIndex {
    height: 100%;
    padding-top: 80px;
  }

  .projects {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    background: #F8F8F8;
    padding-top: 20px;
    font-family: PingFangSC-Semibold;
    text-align: center;

    .word16 {
      color: rgba(0, 0, 0, 0.85);
      font-size: 26px;
      line-height: 37px;
    }

    .word17 {
      color: rgba(0, 0, 0, 0.5);
      font-size: 20px;
      line-height: 28px;
      margin-top: 10px;
    }

    .pic1 {
      display: block;
      width: 100%;
      height: 38%;
      position: absolute;
      bottom: 0;
    }

    .carousel-3d-container {
      width: initial;
    }

    .carousel-3d-slide {
      border: none;
      background: #ffffff;
      box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.25);
    }

    .carousel-3d {
      height: 690px !important;
      margin-left: 200px;
      margin-right: 200px;

    }

    .slideItems {
      height: 100%;
      padding: 10px 15px;
      font-family: PingFangSC-Semibold;

      .info11 {
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.85);
        font-size: 18px;
        line-height: 25px;
        text-align: left;
        margin-top: 40px;
        margin-bottom: 25px;
      }

      .paragraph2 {
        color: rgba(109, 114, 120, 1);
        font-size: 13px;
        line-height: 30px;
        text-align: left;
        padding-top: 29px;
        border-top: 1px solid rgba(0, 0, 0, 0.1);
      }

      .main4 {
        margin-top: 62px;

        .word19 {
          display: block;
          color: rgba(0, 0, 0, 0.5);
          font-size: 12px;
          line-height: 17px;
        }

        > img {
          width: 14px;
          height: 14px;
          display: block;
        }
      }
    }
  }

  .products {
    background: url('../assets/index/productsBgc.png') no-repeat;
    height: 100%;

    .box22 {
      width: 100%;
      flex: 1;
      transition: background-color .5s;

      .section14 {
        width: 192px;
        height: 75px;
        margin-top: 8px;
        margin-left: 85px;
        margin-right: 284px;
        text-align: center;

        .info27 {
          display: block;
          overflow-wrap: break-word;
          color: rgba(0, 0, 0, 0.85);
          font-size: 26px;
          font-family: PingFangSC-Semibold;
          line-height: 37px;
        }

        .word35 {
          display: block;
          color: rgba(0, 0, 0, 0.5);
          font-size: 20px;
          line-height: 28px;
          margin-top: 10px;
        }
      }

      .word36 {
        width: 64px;
        color: #C5C5C5;
        font-size: 53px;
        line-height: 74px;
      }

      .content {
        width: 990px;
        height: 100%;
        margin-left: 57px;
        border-left: 1px solid rgba(0, 0, 0, 0.1);
        position: relative;
        text-align: left;

        &:before {
          content: '';
          position: absolute;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          background: #F8F8F8;
          border: 2px solid #019FD8;
          left: -10px;
          top: 50%;
          transform: translateY(-50%);
        }

        .label14 {
          diplay: block;
          width: 44px;
          height: 44px;
          padding: 8px;
          margin-left: 76px;
          margin-right: 15px;
          border: 1px solid #D8D8D8;
          border-radius: 50%;
        }

        .info16 {
          font-size: 18px;
          font-family: PingFangSC-Semibold, PingFang SC;
          font-weight: 600;
          color: rgba(0, 0, 0, 0.85);
          line-height: 25px;
          margin-bottom: 10px;
        }

        .word37 {
          font-size: 13px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #999999;
          line-height: 22px;
          margin-top: 5px;
        }
      }

      .rightMore {
        // margin-right: 196px;
        .word39 {
          color: rgba(0, 0, 0, 0.5);
          font-size: 12px;
          line-height: 17px;
        }

        .icon11 {
          display: block;
          width: 13px;
          height: 13px;
          background: url('../assets/product-display/icon1.png') no-repeat;
        }
      }

      &:hover {
        // animation: bgChange .5s ease;
        // animation-fill-mode : forwards;
        background: #EEEEEE;

        @keyframes bgChange {
          from {
            background: #ffffff;
          }
          to {
            background: #EEEEEE;
          }
        }

        .word36 {
          color: #019FD8;
        }

        .label14 {
          border: none;
          animation: bgChange-label .5s;
          animation-fill-mode: forwards;

          @keyframes bgChange-label {
            from {
              transform: scale(0);
            }
            to {
              background: #019FD8;
              transform: scale(1)
            }
          }
        }

        .word39 {
          color: #019FD8;
        }

        .icon11 {
          background: url('../assets/project-case/icon-right.png') no-repeat;
        }
      }
    }
  }

  .mouse-wheel-slide-vertical {
    height: 100%;

    &.view {
      padding: 0;
      height: 100%;
    }

    .slide-container {
      position: relative;
      height: 100%;
      font-size: 0;
    }

    .slide-wrapper {
      height: 100%;
      overflow: hidden;

      .slide-page {
        display: inline-block;
        width: 100% !important;
        line-height: 200px;
        text-align: center;
        font-size: 26px;
        transform: translate3d(0, 0, 0);
        backface-visibility: hidden;
      }
    }

    .dots-wrapper {
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);

      .dot {
        display: block;
        margin: 4px 0;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: #eee;

        &.active {
          height: 20px;
          border-radius: 5px;
        }
      }
    }
  }

  .companyIntroduce {
    margin-left: 360px;
    margin-right: 360px;
    height: 100%;
    position: relative;
  }

  .companyContent {
    width: 100%;
    height: 720px;
    position: relative;
  }

  .nineSystem {
    height: 100%;
    background: url('../assets/index/systemBgc.png') no-repeat;
  }

  .nineSystemContent {
    width: 1476px;
    margin-top: 30px;
  }

  .info23 {
    z-index: 243;
    width: 94px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.85);
    font-size: 26px;
    font-family: PingFangSC-Semibold;
    white-space: nowrap;
    line-height: 37px;
    text-align: left;
  }

  .info24 {
    z-index: 244;
    width: 191px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.5);
    font-size: 20px;
    white-space: nowrap;
    line-height: 28px;
    margin-top: 10px;
    // text-align: left;
  }

  .section3 {
    z-index: auto;
    width: 1200px;
    flex: 1;
    padding-top: 20px;
    padding-bottom: 20px;

    .centerLine {
      width: 1px;
      height: 100%;
      background: rgba(0, 0, 0, 0.1);
      margin-left: 30px;
      margin-right: 30px;
    }

    .label1 {
      width: 13px;
      height: 13px;
      display: block;
      margin-right: 6px;
    }

    .icon8 {
      width: 13px;
      height: 13px;
      display: block;
      margin-left: 6px;
    }

    .info2 {
      display: block;
      overflow-wrap: break-word;
      color: rgba(2, 93, 145, 1);
      font-size: 12px;
      letter-spacing: -0.800000011920929px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
      margin-right: 6px;
    }

    .block2 {
      z-index: auto;
      width: 58px;
      height: 98px;
      margin: 1px 0 0 6px;

      .info2 {
        z-index: 51;
        width: 58px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(2, 93, 145, 1);
        font-size: 12px;
        letter-spacing: -0.800000011920929px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
      }

      .word5 {
        z-index: 87;
        width: 58px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(2, 93, 145, 1);
        font-size: 12px;
        letter-spacing: -0.800000011920929px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
        margin-top: 10px;
      }

      .txt1 {
        z-index: 103;
        width: 58px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(2, 93, 145, 1);
        font-size: 12px;
        letter-spacing: -0.800000011920929px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
        margin-top: 10px;
      }

      .word6 {
        z-index: 119;
        width: 58px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(2, 93, 145, 1);
        font-size: 12px;
        letter-spacing: -0.800000011920929px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
        margin-top: 10px;
      }
    }

    .block3 {
      z-index: auto;
      width: 292px;
      height: 98px;
      margin: 1px 0 0 6px;

      .txt2 {
        z-index: 52;
        width: 240px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 12px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
        align-self: flex-start;
      }

      .info3 {
        z-index: 88;
        width: 192px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 12px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
        align-self: flex-start;
        margin-top: 10px;
      }

      .word7 {
        z-index: 104;
        width: 292px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 12px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
        margin-top: 10px;
      }

      .info4 {
        z-index: 120;
        width: 240px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 12px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
        align-self: flex-start;
        margin-top: 10px;
      }
    }

    .block4 {
      z-index: auto;
      width: 36px;
      height: 98px;
      margin: 1px 0 0 143px;

      .word8 {
        z-index: 67;
        width: 36px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
      }

      .info5 {
        z-index: 72;
        width: 36px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
        margin-top: 10px;
      }

      .info6 {
        z-index: 77;
        width: 36px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
        margin-top: 10px;
      }

      .txt3 {
        z-index: 82;
        width: 36px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
        margin-top: 10px;
      }
    }

    .block5 {
      z-index: auto;
      width: 13px;
      height: 94px;
      margin: 3px 0 0 3px;

      .label3 {
        z-index: 73;
        width: 13px;
        height: 13px;
        background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
        100% no-repeat;
        margin-top: 14px;
      }

      .label4 {
        z-index: 78;
        width: 13px;
        height: 13px;
        background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
        100% no-repeat;
        margin-top: 14px;
      }

      .icon4 {
        z-index: 83;
        width: 13px;
        height: 13px;
        background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
        100% no-repeat;
        margin-top: 14px;
      }
    }

    .block6 {
      z-index: 48;
      width: 1px;
      height: 100px;
      background-color: rgba(0, 0, 0, 0.1);
      margin-left: 30px;
    }

    .block7 {
      z-index: auto;
      width: 13px;
      height: 94px;
      margin: 3px 0 0 29px;

      .icon5 {
        z-index: 138;
        width: 13px;
        height: 13px;
      }

      .icon6 {
        z-index: 174;
        width: 13px;
        height: 13px;
        margin-top: 14px;
      }

      .icon7 {
        z-index: 190;
        width: 13px;
        height: 13px;
        margin-top: 14px;
      }
    }

    .block8 {
      z-index: auto;
      width: 58px;
      height: 98px;
      margin: 1px 0 0 6px;

      .info7 {
        z-index: 136;
        width: 58px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(2, 93, 145, 1);
        font-size: 12px;
        letter-spacing: -0.800000011920929px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
      }

      .word9 {
        z-index: 172;
        width: 58px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(2, 93, 145, 1);
        font-size: 12px;
        letter-spacing: -0.800000011920929px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
        margin-top: 10px;
      }

      .info8 {
        z-index: 188;
        width: 58px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(2, 93, 145, 1);
        font-size: 12px;
        letter-spacing: -0.800000011920929px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
        margin-top: 10px;
      }

      .word10 {
        z-index: 204;
        width: 58px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(2, 93, 145, 1);
        font-size: 12px;
        letter-spacing: -0.800000011920929px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
        margin-top: 10px;
      }
    }

    .block9 {
      z-index: auto;
      width: 292px;
      height: 98px;
      margin: 1px 0 0 6px;

      .word11 {
        z-index: 137;
        width: 240px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 12px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
        align-self: flex-start;
      }

      .word12 {
        z-index: 173;
        width: 192px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 12px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
        align-self: flex-start;
        margin-top: 10px;
      }

      .word13 {
        z-index: 189;
        width: 292px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 12px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
        margin-top: 10px;
      }

      .word14 {
        z-index: 205;
        width: 240px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 12px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
        align-self: flex-start;
        margin-top: 10px;
      }
    }

    .block10 {
      z-index: auto;
      width: 36px;
      height: 98px;
      margin: 1px 0 0 143px;

      .info9 {
        z-index: 152;
        width: 36px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
      }

      .word15 {
        z-index: 157;
        width: 36px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
        margin-top: 10px;
      }

      .txt4 {
        z-index: 162;
        width: 36px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
        margin-top: 10px;
      }

      .txt5 {
        z-index: 167;
        width: 36px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
        margin-top: 10px;
      }
    }

    .block11 {
      z-index: auto;
      width: 13px;
      height: 94px;
      margin: 3px 0 0 3px;

      .label6 {
        z-index: 158;
        width: 13px;
        height: 13px;
        background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
        100% no-repeat;
        margin-top: 14px;
      }

      .label7 {
        z-index: 163;
        width: 13px;
        height: 13px;
        background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
        100% no-repeat;
        margin-top: 14px;
      }

      .label8 {
        z-index: 168;
        width: 13px;
        height: 13px;
        background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
        100% no-repeat;
        margin-top: 14px;
      }
    }
  }

  .block1 {
    z-index: auto;
    width: 13px;
    height: 94px;
    margin-top: 3px;

    .icon1 {
      z-index: 89;
      width: 13px;
      height: 13px;
      margin-top: 14px;
    }

    .label2 {
      z-index: 105;
      width: 13px;
      height: 13px;
      margin-top: 14px;
    }

    .icon2 {
      z-index: 121;
      width: 13px;
      height: 13px;
      margin-top: 14px;
    }
  }

  .icon1 {
    z-index: 89;
    width: 13px;
    height: 13px;
    margin-top: 14px;
  }

  .label2 {
    z-index: 105;
    width: 13px;
    height: 13px;
    margin-top: 14px;
  }

  .icon2 {
    z-index: 121;
    width: 13px;
    height: 13px;
    margin-top: 14px;
  }

  .block2 {
    z-index: auto;
    width: 58px;
    height: 98px;
    margin: 1px 0 0 6px;

    .word5 {
      z-index: 87;
      width: 58px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(2, 93, 145, 1);
      font-size: 12px;
      letter-spacing: -0.800000011920929px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
      margin-top: 10px;
    }

    .txt1 {
      z-index: 103;
      width: 58px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(2, 93, 145, 1);
      font-size: 12px;
      letter-spacing: -0.800000011920929px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
      margin-top: 10px;
    }

    .word6 {
      z-index: 119;
      width: 58px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(2, 93, 145, 1);
      font-size: 12px;
      letter-spacing: -0.800000011920929px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
      margin-top: 10px;
    }
  }

  .word5 {
    z-index: 87;
    width: 58px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(2, 93, 145, 1);
    font-size: 12px;
    letter-spacing: -0.800000011920929px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
    margin-top: 10px;
  }

  .txt1 {
    z-index: 103;
    width: 58px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(2, 93, 145, 1);
    font-size: 12px;
    letter-spacing: -0.800000011920929px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
    margin-top: 10px;
  }

  .word6 {
    z-index: 119;
    width: 58px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(2, 93, 145, 1);
    font-size: 12px;
    letter-spacing: -0.800000011920929px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
    margin-top: 10px;
  }

  .block3 {
    z-index: auto;
    width: 292px;
    height: 98px;
    margin: 1px 0 0 6px;

    .txt2 {
      z-index: 52;
      width: 240px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 12px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
      align-self: flex-start;
    }

    .info3 {
      z-index: 88;
      width: 192px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 12px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
      align-self: flex-start;
      margin-top: 10px;
    }

    .word7 {
      z-index: 104;
      width: 292px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 12px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
      margin-top: 10px;
    }

    .info4 {
      z-index: 120;
      width: 240px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 12px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
      align-self: flex-start;
      margin-top: 10px;
    }
  }

  .txt2 {
    z-index: 52;
    width: 240px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(51, 51, 51, 1);
    font-size: 12px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
    align-self: flex-start;
  }

  .info3 {
    z-index: 88;
    width: 192px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(51, 51, 51, 1);
    font-size: 12px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
    align-self: flex-start;
    margin-top: 10px;
  }

  .word7 {
    z-index: 104;
    width: 292px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(51, 51, 51, 1);
    font-size: 12px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
    margin-top: 10px;
  }

  .info4 {
    z-index: 120;
    width: 240px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(51, 51, 51, 1);
    font-size: 12px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
    align-self: flex-start;
    margin-top: 10px;
  }

  .block4 {
    z-index: auto;
    width: 36px;
    height: 98px;
    margin: 1px 0 0 143px;

    .word8 {
      z-index: 67;
      width: 36px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
    }

    .info5 {
      z-index: 72;
      width: 36px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
      margin-top: 10px;
    }

    .info6 {
      z-index: 77;
      width: 36px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
      margin-top: 10px;
    }

    .txt3 {
      z-index: 82;
      width: 36px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
      margin-top: 10px;
    }
  }

  .word8 {
    z-index: 67;
    width: 36px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
  }

  .info5 {
    z-index: 72;
    width: 36px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
    margin-top: 10px;
  }

  .info6 {
    z-index: 77;
    width: 36px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
    margin-top: 10px;
  }

  .txt3 {
    z-index: 82;
    width: 36px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
    margin-top: 10px;
  }

  .block5 {
    z-index: auto;
    width: 13px;
    height: 94px;
    margin: 3px 0 0 3px;

    .label3 {
      z-index: 73;
      width: 13px;
      height: 13px;
      background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
      100% no-repeat;
      margin-top: 14px;
    }

    .label4 {
      z-index: 78;
      width: 13px;
      height: 13px;
      background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
      100% no-repeat;
      margin-top: 14px;
    }

    .icon4 {
      z-index: 83;
      width: 13px;
      height: 13px;
      background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
      100% no-repeat;
      margin-top: 14px;
    }
  }

  .label3 {
    z-index: 73;
    width: 13px;
    height: 13px;
    background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
    100% no-repeat;
    margin-top: 14px;
  }

  .label4 {
    z-index: 78;
    width: 13px;
    height: 13px;
    background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
    100% no-repeat;
    margin-top: 14px;
  }

  .icon4 {
    z-index: 83;
    width: 13px;
    height: 13px;
    background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
    100% no-repeat;
    margin-top: 14px;
  }

  .block6 {
    z-index: 48;
    width: 1px;
    height: 100px;
    background-color: rgba(0, 0, 0, 0.1);
    margin-left: 30px;
  }

  .block7 {
    z-index: auto;
    width: 13px;
    height: 94px;
    margin: 3px 0 0 29px;

    .icon5 {
      z-index: 138;
      width: 13px;
      height: 13px;
    }

    .icon6 {
      z-index: 174;
      width: 13px;
      height: 13px;
      margin-top: 14px;
    }

    .icon7 {
      z-index: 190;
      width: 13px;
      height: 13px;
      margin-top: 14px;
    }
  }

  .icon5 {
    z-index: 138;
    width: 13px;
    height: 13px;
  }

  .icon6 {
    z-index: 174;
    width: 13px;
    height: 13px;
    margin-top: 14px;
  }

  .icon7 {
    z-index: 190;
    width: 13px;
    height: 13px;
    margin-top: 14px;
  }

  .block8 {
    z-index: auto;
    width: 58px;
    height: 98px;
    margin: 1px 0 0 6px;

    .info7 {
      z-index: 136;
      width: 58px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(2, 93, 145, 1);
      font-size: 12px;
      letter-spacing: -0.800000011920929px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
    }

    .word9 {
      z-index: 172;
      width: 58px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(2, 93, 145, 1);
      font-size: 12px;
      letter-spacing: -0.800000011920929px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
      margin-top: 10px;
    }

    .info8 {
      z-index: 188;
      width: 58px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(2, 93, 145, 1);
      font-size: 12px;
      letter-spacing: -0.800000011920929px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
      margin-top: 10px;
    }

    .word10 {
      z-index: 204;
      width: 58px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(2, 93, 145, 1);
      font-size: 12px;
      letter-spacing: -0.800000011920929px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
      margin-top: 10px;
    }
  }

  .info7 {
    z-index: 136;
    width: 58px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(2, 93, 145, 1);
    font-size: 12px;
    letter-spacing: -0.800000011920929px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
  }

  .word9 {
    z-index: 172;
    width: 58px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(2, 93, 145, 1);
    font-size: 12px;
    letter-spacing: -0.800000011920929px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
    margin-top: 10px;
  }

  .info8 {
    z-index: 188;
    width: 58px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(2, 93, 145, 1);
    font-size: 12px;
    letter-spacing: -0.800000011920929px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
    margin-top: 10px;
  }

  .word10 {
    z-index: 204;
    width: 58px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(2, 93, 145, 1);
    font-size: 12px;
    letter-spacing: -0.800000011920929px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
    margin-top: 10px;
  }

  .block9 {
    z-index: auto;
    width: 292px;
    height: 98px;
    margin: 1px 0 0 6px;

    .word11 {
      z-index: 137;
      width: 240px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 12px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
      align-self: flex-start;
    }

    .word12 {
      z-index: 173;
      width: 192px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 12px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
      align-self: flex-start;
      margin-top: 10px;
    }

    .word13 {
      z-index: 189;
      width: 292px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 12px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
      margin-top: 10px;
    }

    .word14 {
      z-index: 205;
      width: 240px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 12px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
      align-self: flex-start;
      margin-top: 10px;
    }
  }

  .word11 {
    z-index: 137;
    width: 240px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(51, 51, 51, 1);
    font-size: 12px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
    align-self: flex-start;
  }

  .word12 {
    z-index: 173;
    width: 192px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(51, 51, 51, 1);
    font-size: 12px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
    align-self: flex-start;
    margin-top: 10px;
  }

  .word13 {
    z-index: 189;
    width: 292px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(51, 51, 51, 1);
    font-size: 12px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
    margin-top: 10px;
  }

  .word14 {
    z-index: 205;
    width: 240px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(51, 51, 51, 1);
    font-size: 12px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
    align-self: flex-start;
    margin-top: 10px;
  }

  .block10 {
    z-index: auto;
    width: 36px;
    height: 98px;
    margin: 1px 0 0 143px;

    .info9 {
      z-index: 152;
      width: 36px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
    }

    .word15 {
      z-index: 157;
      width: 36px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
      margin-top: 10px;
    }

    .txt4 {
      z-index: 162;
      width: 36px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
      margin-top: 10px;
    }

    .txt5 {
      z-index: 167;
      width: 36px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
      margin-top: 10px;
    }
  }

  .info9 {
    z-index: 152;
    width: 36px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
  }

  .word15 {
    z-index: 157;
    width: 36px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
    margin-top: 10px;
  }

  .txt4 {
    z-index: 162;
    width: 36px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
    margin-top: 10px;
  }

  .txt5 {
    z-index: 167;
    width: 36px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
    margin-top: 10px;
  }

  .block11 {
    z-index: auto;
    width: 13px;
    height: 94px;
    margin: 3px 0 0 3px;

    .label6 {
      z-index: 158;
      width: 13px;
      height: 13px;
      background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
      100% no-repeat;
      margin-top: 14px;
    }

    .label7 {
      z-index: 163;
      width: 13px;
      height: 13px;
      background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
      100% no-repeat;
      margin-top: 14px;
    }

    .label8 {
      z-index: 168;
      width: 13px;
      height: 13px;
      background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
      100% no-repeat;
      margin-top: 14px;
    }
  }

  .label6 {
    z-index: 158;
    width: 13px;
    height: 13px;
    background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
    100% no-repeat;
    margin-top: 14px;
  }

  .label7 {
    z-index: 163;
    width: 13px;
    height: 13px;
    background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
    100% no-repeat;
    margin-top: 14px;
  }

  .label8 {
    z-index: 168;
    width: 13px;
    height: 13px;
    background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
    100% no-repeat;
    margin-top: 14px;
  }

  .txt6 {
    z-index: 235;
    width: 800px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.85);
    font-size: 26px;
    font-family: PingFangSC-Semibold;
    white-space: nowrap;
    line-height: 37px;
    text-align: center;
  }

  .info10 {
    z-index: 236;
    width: 800px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.5);
    font-size: 20px;
    white-space: nowrap;
    line-height: 28px;
    text-align: center;
    margin-bottom: 20px;
  }

  .box3 {
    z-index: 232;
    height: 500px;
    background-color: rgba(248, 248, 248, 1);
    width: 800px;
    justify-content: flex-end;
    align-items: flex-end;
    position: absolute;
    right: 0;
    top: 0;

    .mod3 {
      z-index: auto;
      width: 767px;
      height: 480px;

      .paragraph4 {
        z-index: 237;
        width: 736px;
        height: 80px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 18px;
        letter-spacing: 1.350000023841858px;
        font-family: FontName;
        line-height: 40px;
        text-align: left;
        align-self: flex-start;
        overflow: hidden;
        text-overflow: ellipsis;
      }

      .word28 {
        z-index: 447;
        width: 86px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(2, 93, 145, 1);
        font-size: 20px;
        letter-spacing: 1.5px;
        font-family: FontName;
        white-space: nowrap;
        line-height: 20px;
        text-align: left;
        align-self: flex-start;
        margin: 25px 0 0 1px;
      }

      .block15 {
        z-index: 444;
        width: 100px;
        height: 1px;
        background-image: linear-gradient(
          270deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(2, 93, 145, 1) 100%
        );
        align-self: flex-start;
        margin-top: 15px;
      }

      .info20 {
        z-index: 441;
        width: 426px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 18px;
        letter-spacing: 1.350000023841858px;
        white-space: nowrap;
        line-height: 35px;
        text-align: left;
        align-self: flex-start;
        margin-top: 15px;
      }

      .block16 {
        z-index: auto;
        width: 767px;
        height: 281px;
        margin-top: 8px;
        justify-content: space-between;

        .outer13 {
          z-index: auto;
          width: 465px;
          height: 197px;
          margin-top: 17px;

          .info21 {
            z-index: 448;
            width: 151px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(2, 93, 145, 1);
            font-size: 20px;
            letter-spacing: 1.5px;
            font-family: FontName;
            white-space: nowrap;
            line-height: 20px;
            text-align: left;
            align-self: flex-start;
            margin-left: 1px;
          }

          .box4 {
            z-index: 445;
            width: 100px;
            height: 1px;
            background-image: linear-gradient(
              270deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(2, 93, 145, 1) 100%
            );
            align-self: flex-start;
            margin-top: 15px;
          }

          .word29 {
            z-index: 442;
            width: 291px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(51, 51, 51, 1);
            font-size: 18px;
            letter-spacing: 1.350000023841858px;
            white-space: nowrap;
            line-height: 35px;
            text-align: left;
            align-self: flex-start;
            margin-top: 15px;
          }

          .info22 {
            z-index: 449;
            width: 86px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(2, 93, 145, 1);
            font-size: 20px;
            letter-spacing: 1.5px;
            font-family: FontName;
            white-space: nowrap;
            line-height: 20px;
            text-align: left;
            align-self: flex-start;
            margin: 25px 0 0 1px;
          }

          .box5 {
            z-index: 446;
            width: 100px;
            height: 1px;
            background-image: linear-gradient(
              270deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(2, 93, 145, 1) 100%
            );
            align-self: flex-start;
            margin-top: 15px;
          }

          .txt15 {
            z-index: 443;
            width: 465px;
            display: block;
            overflow-wrap: break-word;
            color: rgba(51, 51, 51, 1);
            font-size: 18px;
            letter-spacing: 1.350000023841858px;
            white-space: nowrap;
            line-height: 35px;
            text-align: left;
            margin-top: 15px;
          }
        }
      }
    }
  }

  .mod3 {
    z-index: auto;
    width: 767px;
    height: 480px;

    .paragraph4 {
      z-index: 237;
      width: 736px;
      height: 80px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 18px;
      letter-spacing: 1.350000023841858px;
      font-family: FontName;
      line-height: 40px;
      text-align: left;
      align-self: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .word28 {
      z-index: 447;
      width: 86px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(2, 93, 145, 1);
      font-size: 20px;
      letter-spacing: 1.5px;
      font-family: FontName;
      white-space: nowrap;
      line-height: 20px;
      text-align: left;
      align-self: flex-start;
      margin: 25px 0 0 1px;
    }

    .block15 {
      z-index: 444;
      width: 100px;
      height: 1px;
      background-image: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(2, 93, 145, 1) 100%
      );
      align-self: flex-start;
      margin-top: 15px;
    }

    .info20 {
      z-index: 441;
      width: 426px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 18px;
      letter-spacing: 1.350000023841858px;
      white-space: nowrap;
      line-height: 35px;
      text-align: left;
      align-self: flex-start;
      margin-top: 15px;
    }

    .block16 {
      z-index: auto;
      width: 767px;
      height: 281px;
      margin-top: 8px;
      justify-content: space-between;

      .outer13 {
        z-index: auto;
        width: 465px;
        height: 197px;
        margin-top: 17px;

        .info21 {
          z-index: 448;
          width: 151px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(2, 93, 145, 1);
          font-size: 20px;
          letter-spacing: 1.5px;
          font-family: FontName;
          white-space: nowrap;
          line-height: 20px;
          text-align: left;
          align-self: flex-start;
          margin-left: 1px;
        }

        .box4 {
          z-index: 445;
          width: 100px;
          height: 1px;
          background-image: linear-gradient(
            270deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(2, 93, 145, 1) 100%
          );
          align-self: flex-start;
          margin-top: 15px;
        }

        .word29 {
          z-index: 442;
          width: 291px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(51, 51, 51, 1);
          font-size: 18px;
          letter-spacing: 1.350000023841858px;
          white-space: nowrap;
          line-height: 35px;
          text-align: left;
          align-self: flex-start;
          margin-top: 15px;
        }

        .info22 {
          z-index: 449;
          width: 86px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(2, 93, 145, 1);
          font-size: 20px;
          letter-spacing: 1.5px;
          font-family: FontName;
          white-space: nowrap;
          line-height: 20px;
          text-align: left;
          align-self: flex-start;
          margin: 25px 0 0 1px;
        }

        .box5 {
          z-index: 446;
          width: 100px;
          height: 1px;
          background-image: linear-gradient(
            270deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(2, 93, 145, 1) 100%
          );
          align-self: flex-start;
          margin-top: 15px;
        }

        .txt15 {
          z-index: 443;
          width: 465px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(51, 51, 51, 1);
          font-size: 18px;
          letter-spacing: 1.350000023841858px;
          white-space: nowrap;
          line-height: 35px;
          text-align: left;
          margin-top: 15px;
        }
      }

      .img3 {
        z-index: 233;
        width: 212px;
        height: 281px;
      }
    }
  }

  .paragraph4 {
    z-index: 237;
    width: 736px;
    height: 80px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(51, 51, 51, 1);
    font-size: 18px;
    letter-spacing: 1.350000023841858px;
    font-family: FontName;
    line-height: 40px;
    text-align: left;
    align-self: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .word28 {
    z-index: 447;
    width: 86px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(2, 93, 145, 1);
    font-size: 20px;
    letter-spacing: 1.5px;
    font-family: FontName;
    white-space: nowrap;
    line-height: 20px;
    text-align: left;
    align-self: flex-start;
    margin: 25px 0 0 1px;
  }

  .block15 {
    z-index: 444;
    width: 100px;
    height: 1px;
    background-image: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(2, 93, 145, 1) 100%
    );
    align-self: flex-start;
    margin-top: 15px;
  }

  .info20 {
    z-index: 441;
    width: 426px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(51, 51, 51, 1);
    font-size: 18px;
    letter-spacing: 1.350000023841858px;
    white-space: nowrap;
    line-height: 35px;
    text-align: left;
    align-self: flex-start;
    margin-top: 15px;
  }

  .block16 {
    z-index: auto;
    width: 767px;
    height: 281px;
    margin-top: 8px;
    justify-content: space-between;

    .outer13 {
      z-index: auto;
      width: 465px;
      height: 197px;
      margin-top: 17px;

      .info21 {
        z-index: 448;
        width: 151px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(2, 93, 145, 1);
        font-size: 20px;
        letter-spacing: 1.5px;
        font-family: FontName;
        white-space: nowrap;
        line-height: 20px;
        text-align: left;
        align-self: flex-start;
        margin-left: 1px;
      }

      .box4 {
        z-index: 445;
        width: 100px;
        height: 1px;
        background-image: linear-gradient(
          270deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(2, 93, 145, 1) 100%
        );
        align-self: flex-start;
        margin-top: 15px;
      }

      .word29 {
        z-index: 442;
        width: 291px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 18px;
        letter-spacing: 1.350000023841858px;
        white-space: nowrap;
        line-height: 35px;
        text-align: left;
        align-self: flex-start;
        margin-top: 15px;
      }

      .info22 {
        z-index: 449;
        width: 86px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(2, 93, 145, 1);
        font-size: 20px;
        letter-spacing: 1.5px;
        font-family: FontName;
        white-space: nowrap;
        line-height: 20px;
        text-align: left;
        align-self: flex-start;
        margin: 25px 0 0 1px;
      }

      .box5 {
        z-index: 446;
        width: 100px;
        height: 1px;
        background-image: linear-gradient(
          270deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(2, 93, 145, 1) 100%
        );
        align-self: flex-start;
        margin-top: 15px;
      }

      .txt15 {
        z-index: 443;
        width: 465px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(51, 51, 51, 1);
        font-size: 18px;
        letter-spacing: 1.350000023841858px;
        white-space: nowrap;
        line-height: 35px;
        text-align: left;
        margin-top: 15px;
      }
    }
  }

  .outer13 {
    z-index: auto;
    width: 465px;
    height: 197px;
    margin-top: 17px;

    .info21 {
      z-index: 448;
      width: 151px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(2, 93, 145, 1);
      font-size: 20px;
      letter-spacing: 1.5px;
      font-family: FontName;
      white-space: nowrap;
      line-height: 20px;
      text-align: left;
      align-self: flex-start;
      margin-left: 1px;
    }

    .box4 {
      z-index: 445;
      width: 100px;
      height: 1px;
      background-image: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(2, 93, 145, 1) 100%
      );
      align-self: flex-start;
      margin-top: 15px;
    }

    .word29 {
      z-index: 442;
      width: 291px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 18px;
      letter-spacing: 1.350000023841858px;
      white-space: nowrap;
      line-height: 35px;
      text-align: left;
      align-self: flex-start;
      margin-top: 15px;
    }

    .info22 {
      z-index: 449;
      width: 86px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(2, 93, 145, 1);
      font-size: 20px;
      letter-spacing: 1.5px;
      font-family: FontName;
      white-space: nowrap;
      line-height: 20px;
      text-align: left;
      align-self: flex-start;
      margin: 25px 0 0 1px;
    }

    .box5 {
      z-index: 446;
      width: 100px;
      height: 1px;
      background-image: linear-gradient(
        270deg,
        rgba(255, 255, 255, 0) 0%,
        rgba(2, 93, 145, 1) 100%
      );
      align-self: flex-start;
      margin-top: 15px;
    }

    .txt15 {
      z-index: 443;
      width: 465px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(51, 51, 51, 1);
      font-size: 18px;
      letter-spacing: 1.350000023841858px;
      white-space: nowrap;
      line-height: 35px;
      text-align: left;
      margin-top: 15px;
    }
  }

  .info21 {
    z-index: 448;
    width: 151px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(2, 93, 145, 1);
    font-size: 20px;
    letter-spacing: 1.5px;
    font-family: FontName;
    white-space: nowrap;
    line-height: 20px;
    text-align: left;
    align-self: flex-start;
    margin-left: 1px;
  }

  .box4 {
    z-index: 445;
    width: 100px;
    height: 1px;
    background-image: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(2, 93, 145, 1) 100%
    );
    align-self: flex-start;
    margin-top: 15px;
  }

  .word29 {
    z-index: 442;
    width: 291px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(51, 51, 51, 1);
    font-size: 18px;
    letter-spacing: 1.350000023841858px;
    white-space: nowrap;
    line-height: 35px;
    text-align: left;
    align-self: flex-start;
    margin-top: 15px;
  }

  .info22 {
    z-index: 449;
    width: 86px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(2, 93, 145, 1);
    font-size: 20px;
    letter-spacing: 1.5px;
    font-family: FontName;
    white-space: nowrap;
    line-height: 20px;
    text-align: left;
    align-self: flex-start;
    margin: 25px 0 0 1px;
  }

  .box5 {
    z-index: 446;
    width: 100px;
    height: 1px;
    background-image: linear-gradient(
      270deg,
      rgba(255, 255, 255, 0) 0%,
      rgba(2, 93, 145, 1) 100%
    );
    align-self: flex-start;
    margin-top: 15px;
  }

  .txt15 {
    z-index: 443;
    width: 465px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(51, 51, 51, 1);
    font-size: 18px;
    letter-spacing: 1.350000023841858px;
    white-space: nowrap;
    line-height: 35px;
    text-align: left;
    margin-top: 15px;
  }

  .leftContent {
    width: 850px;
    height: 490px;
    position: absolute;
    left: 0;
    bottom: 0;
  }

  .mod2 {
    width: 600px;
    height: 440px;
    border-width: 10px;
    border: 10px solid rgba(240, 240, 240, 1);
    background-color: rgba(216, 216, 216, 0);
    position: absolute;
    top: 0;
    left: 0;
  }

  .pic3 {
    width: 800px;
    height: 440px;
    position: absolute;
    right: 0;
    bottom: 0;
  }

  .wrap2 {
    z-index: auto;
    width: 266px;
    height: 142px;

    .word34 {
      z-index: 396;
      width: 144px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 26px;
      font-family: PingFangSC-Semibold;
      white-space: nowrap;
      line-height: 37px;
      text-align: left;
      align-self: flex-start;
    }

    .paragraph12 {
      z-index: 395;
      width: 266px;
      height: 90px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      line-height: 30px;
      text-align: left;
      margin-top: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .word34 {
    z-index: 396;
    width: 144px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 26px;
    font-family: PingFangSC-Semibold;
    white-space: nowrap;
    line-height: 37px;
    text-align: left;
    align-self: flex-start;
  }

  .paragraph12 {
    z-index: 395;
    width: 266px;
    height: 90px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    margin-top: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mod13 {
    z-index: auto;
    width: 266px;
    height: 142px;

    .txt18 {
      z-index: 403;
      width: 144px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 26px;
      font-family: PingFangSC-Semibold;
      white-space: nowrap;
      line-height: 37px;
      text-align: left;
      align-self: flex-start;
    }

    .paragraph11 {
      z-index: 402;
      width: 266px;
      height: 90px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      line-height: 30px;
      text-align: left;
      margin-top: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .txt18 {
    z-index: 403;
    width: 144px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 26px;
    font-family: PingFangSC-Semibold;
    white-space: nowrap;
    line-height: 37px;
    text-align: left;
    align-self: flex-start;
  }

  .paragraph11 {
    z-index: 402;
    width: 266px;
    height: 90px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    margin-top: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .margin-bottom-17 {
    margin-bottom: 17px;
  }

  .box11 {
    z-index: 343;
    height: 450px;
    background-color: rgba(216, 216, 216, 1);
    width: 300px;
    justify-content: flex-start;
    position: relative;
    overflow: hidden;
    cursor: pointer;
    border-radius: 18px;

    &:hover > img {
      transform: scale(1.25);
      transition: all 0.3s ease;
    }

    &:before {
      // content: '';
      width: 100%;
      height: 100%;
      position: absolute;
      left: 0;
      top: 0;
      z-index: 9;
      background: linear-gradient(135deg, #2568F1 0%, rgba(123, 163, 239, 0.6) 100%);
    }

    > img {
      z-index: 6;
      width: 100%;
      height: 100%;
      display: block;
    }

    > div {
      z-index: auto;
      width: 100%;
      height: 100%;
      padding: 46px 16px;
      overflow: hidden;
      position: absolute;
      z-index: 12;

      .word30 {
        z-index: 346;
        // width: 191px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 26px;
        font-family: PingFangSC-Semibold;
        white-space: nowrap;
        line-height: 37px;
        text-align: left;
        align-self: flex-start;
      }

      .paragraph5 {
        flex: 1;
        width: 100%;
        height: 100%;
        display: block;
        overflow-wrap: break-word;
        color: rgba(255, 255, 255, 1);
        font-size: 14px;
        line-height: 30px;
        text-align: left;
        margin-top: 15px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  .mod7 {
    height: 234px;
    width: 520px;

    &:before {
      background: linear-gradient(135deg, #32CEC7 0%, rgba(137, 255, 249, 0.7) 100%);
    }
  }

  .mod5 {
    height: 234px;
    width: 320px;

    &:before {
      background: linear-gradient(135deg, #5196FA 0%, rgba(133, 173, 223, 0.7) 100%);
    }
  }

  .mod6 {
    height: 204px;
    width: 320px;

    &:before {

      background: linear-gradient(135deg, #1F92FE 0%, rgba(164, 213, 255, 0.75) 100%);
    }
  }

  .bd2 {
    height: 204px;
    width: 254px;

    &:before {
      background: linear-gradient(135deg, #5196FA 0%, rgba(133, 173, 223, 0.8) 100%);
    }
  }

  .bd4 {
    height: 204px;
    width: 254px;

    &:before {
      background: linear-gradient(135deg, #32CEC7 0%, rgba(137, 255, 249, 0.75) 100%);
    }
  }

  .box16 {
    height: 233px;
    width: 732px;

    &:before {
      background: linear-gradient(135deg, #32CEC7 0%, rgba(137, 255, 249, 0.75) 100%);
    }
  }

  .box18 {
    height: 233px;
    width: 732px;

    &:before {
      background: linear-gradient(135deg, #5196FA 0%, rgba(133, 173, 223, 0.85) 100%);
    }
  }

  .main7 {
    z-index: auto;
    width: 248px;
    height: 112px;

    .info25 {
      z-index: 354;
      width: 248px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 26px;
      font-family: PingFangSC-Semibold;
      white-space: nowrap;
      line-height: 37px;
      text-align: left;
    }

    .paragraph6 {
      z-index: 353;
      width: 224px;
      height: 60px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      line-height: 30px;
      text-align: left;
      align-self: flex-start;
      margin-top: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .info25 {
    z-index: 354;
    width: 248px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 26px;
    font-family: PingFangSC-Semibold;
    white-space: nowrap;
    line-height: 37px;
    text-align: left;
  }

  .paragraph6 {
    z-index: 353;
    width: 224px;
    height: 60px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    align-self: flex-start;
    margin-top: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .main9 {
    z-index: auto;
    width: 280px;
    height: 142px;

    .word31 {
      z-index: 368;
      width: 144px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 26px;
      font-family: PingFangSC-Semibold;
      white-space: nowrap;
      line-height: 37px;
      text-align: left;
      align-self: flex-start;
    }

    .paragraph8 {
      z-index: 367;
      width: 280px;
      height: 90px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      line-height: 30px;
      text-align: left;
      margin-top: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .word31 {
    z-index: 368;
    width: 144px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 26px;
    font-family: PingFangSC-Semibold;
    white-space: nowrap;
    line-height: 37px;
    text-align: left;
    align-self: flex-start;
  }

  .paragraph8 {
    z-index: 367;
    width: 280px;
    height: 90px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    margin-top: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .section13 {
    z-index: auto;
    width: 248px;
    height: 112px;

    .txt16 {
      z-index: 361;
      width: 248px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 26px;
      font-family: PingFangSC-Semibold;
      white-space: nowrap;
      line-height: 37px;
      text-align: left;
    }

    .paragraph7 {
      z-index: 360;
      width: 224px;
      height: 60px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      line-height: 30px;
      text-align: left;
      align-self: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 15px 0 0 1px;
    }
  }

  .txt16 {
    z-index: 361;
    width: 248px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 26px;
    font-family: PingFangSC-Semibold;
    white-space: nowrap;
    line-height: 37px;
    text-align: left;
  }

  .paragraph7 {
    z-index: 360;
    width: 224px;
    height: 60px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    align-self: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 15px 0 0 1px;
  }

  .bd3 {
    z-index: auto;
    width: 154px;
    height: 145px;

    .word32 {
      z-index: 375;
      width: 144px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 26px;
      font-family: PingFangSC-Semibold;
      white-space: nowrap;
      line-height: 37px;
      text-align: left;
      align-self: flex-end;
    }

    .paragraph9 {
      z-index: 374;
      width: 140px;
      height: 90px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      line-height: 30px;
      text-align: left;
      align-self: flex-start;
      margin-top: 18px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .word32 {
    z-index: 375;
    width: 144px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 26px;
    font-family: PingFangSC-Semibold;
    white-space: nowrap;
    line-height: 37px;
    text-align: left;
    align-self: flex-end;
  }

  .paragraph9 {
    z-index: 374;
    width: 140px;
    height: 90px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    align-self: flex-start;
    margin-top: 18px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .main10 {
    z-index: auto;
    width: 144px;
    height: 142px;

    .word33 {
      z-index: 382;
      width: 144px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 26px;
      font-family: PingFangSC-Semibold;
      white-space: nowrap;
      line-height: 37px;
      text-align: left;
    }

    .infoBox1 {
      z-index: 381;
      width: 140px;
      height: 90px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      line-height: 30px;
      text-align: left;
      align-self: flex-start;
      margin-top: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .word33 {
    z-index: 382;
    width: 144px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 26px;
    font-family: PingFangSC-Semibold;
    white-space: nowrap;
    line-height: 37px;
    text-align: left;
  }

  .infoBox1 {
    z-index: 381;
    width: 140px;
    height: 90px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    align-self: flex-start;
    margin-top: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .mod11 {
    z-index: auto;
    width: 224px;
    height: 172px;

    .txt17 {
      z-index: 389;
      width: 143px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 26px;
      font-family: PingFangSC-Semibold;
      white-space: nowrap;
      line-height: 37px;
      text-align: left;
      align-self: flex-start;
    }

    .paragraph10 {
      z-index: 388;
      width: 224px;
      height: 120px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(255, 255, 255, 1);
      font-size: 14px;
      line-height: 30px;
      text-align: left;
      margin-top: 15px;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .txt17 {
    z-index: 389;
    width: 143px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 26px;
    font-family: PingFangSC-Semibold;
    white-space: nowrap;
    line-height: 37px;
    text-align: left;
    align-self: flex-start;
  }

  .paragraph10 {
    z-index: 388;
    width: 224px;
    height: 120px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(255, 255, 255, 1);
    font-size: 14px;
    line-height: 30px;
    text-align: left;
    margin-top: 15px;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .main3 {
    z-index: 42;
    width: 450px;
    height: 1px;
    background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngcfc973eda2586e546a84443b652bd520f86159cc8e909841854e1b90212571c8'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngcfc973eda2586e546a84443b652bd520f86159cc8e909841854e1b90212571c8)
    0px -1px no-repeat;
    margin-top: 25px;
  }

  .word19 {
    z-index: 38;
    width: 36px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
  }

  .bd1 {
    z-index: auto;
    width: 461px;
    height: 557px;

    .word20 {
      z-index: 24;
      width: 108px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.85);
      font-size: 18px;
      font-family: PingFangSC-Semibold;
      white-space: nowrap;
      line-height: 25px;
      text-align: left;
      align-self: flex-start;
      margin: 40px 0 0 21px;
    }

    .group5 {
      z-index: 31;
      width: 460px;
      height: 1px;
      background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4152f5a15a42da1290531e263849cd8163a422939ab398f0d318e97fd1cd8c40'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4152f5a15a42da1290531e263849cd8163a422939ab398f0d318e97fd1cd8c40)
      0px 0px no-repeat;
      align-self: flex-end;
      margin-top: 15px;
    }

    .paragraph3 {
      z-index: 25;
      width: 416px;
      height: 90px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(109, 114, 120, 1);
      font-size: 13px;
      line-height: 30px;
      text-align: left;
      align-self: flex-start;
      overflow: hidden;
      text-overflow: ellipsis;
      margin: 29px 0 0 21px;
    }

    .group6 {
      z-index: auto;
      width: 52px;
      height: 17px;
      justify-content: space-between;
      margin: 30px 0 0 203px;

      .info12 {
        z-index: 27;
        width: 36px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
      }
    }
  }

  .word20 {
    z-index: 24;
    width: 108px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.85);
    font-size: 18px;
    font-family: PingFangSC-Semibold;
    white-space: nowrap;
    line-height: 25px;
    text-align: left;
    align-self: flex-start;
    margin: 40px 0 0 21px;
  }

  .group5 {
    z-index: 31;
    width: 460px;
    height: 1px;
    background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4152f5a15a42da1290531e263849cd8163a422939ab398f0d318e97fd1cd8c40'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng4152f5a15a42da1290531e263849cd8163a422939ab398f0d318e97fd1cd8c40)
    0px 0px no-repeat;
    align-self: flex-end;
    margin-top: 15px;
  }

  .paragraph3 {
    z-index: 25;
    width: 416px;
    height: 90px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(109, 114, 120, 1);
    font-size: 13px;
    line-height: 30px;
    text-align: left;
    align-self: flex-start;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 29px 0 0 21px;
  }

  .group6 {
    z-index: auto;
    width: 52px;
    height: 17px;
    justify-content: space-between;
    margin: 30px 0 0 203px;

    .info12 {
      z-index: 27;
      width: 36px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
    }
  }

  .info12 {
    z-index: 27;
    width: 36px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
  }

  .section10 {
    z-index: 246;
    height: 360px;
    background-color: rgba(248, 248, 248, 1);
    width: 100%;

    .outer5 {
      z-index: auto;
      width: 144px;
      height: 25px;
      margin: 95px 0 0 817px;

      .word21 {
        z-index: 262;
        width: 144px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.85);
        font-size: 18px;
        font-family: PingFangSC-Semibold;
        white-space: nowrap;
        line-height: 25px;
        text-align: left;
      }
    }

    .outer6 {
      z-index: auto;
      width: 1164px;
      height: 103px;
      margin: 15px 0 0 560px;

      .info13 {
        z-index: 254;
        width: 54px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(197, 197, 197, 1);
        font-size: 53px;
        white-space: nowrap;
        line-height: 74px;
        text-align: left;
        margin-top: 8px;
      }

      .outer7 {
        z-index: 259;
        height: 44px;
        border-radius: 50%;
        background: url('../assets/index/img13-1.png'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng5963796593919d09b823661cc6b683bee3e942bcb5bc35ec494a11da75ab9e2e)
        100% no-repeat;
        margin-left: 144px;
        width: 44px;
        justify-content: center;
        align-items: center;

        .icon10 {
          z-index: 260;
          width: 27px;
          height: 28px;
        }
      }

      .outer8 {
        z-index: auto;
        width: 308px;
        height: 103px;
        margin-left: 15px;

        .word22 {
          z-index: 263;
          width: 308px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 13px;
          letter-spacing: 0.9750000238418579px;
          font-family: PingFangSC-Medium;
          white-space: nowrap;
          line-height: 22px;
          text-align: left;
        }

        .info14 {
          z-index: 264;
          width: 168px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 13px;
          letter-spacing: 0.9750000238418579px;
          font-family: PingFangSC-Medium;
          white-space: nowrap;
          line-height: 22px;
          text-align: left;
          align-self: flex-start;
          margin-top: 5px;
        }

        .word23 {
          z-index: 265;
          width: 224px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 13px;
          letter-spacing: 0.9750000238418579px;
          font-family: PingFangSC-Medium;
          white-space: nowrap;
          line-height: 22px;
          text-align: left;
          align-self: flex-start;
          margin-top: 5px;
        }

        .txt8 {
          z-index: 266;
          width: 112px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 13px;
          letter-spacing: 0.9750000238418579px;
          font-family: PingFangSC-Medium;
          white-space: nowrap;
          line-height: 22px;
          text-align: left;
          align-self: flex-start;
          margin-top: 5px;
        }
      }

      .word24 {
        z-index: 294;
        width: 36px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
        margin: 37px 0 0 547px;
      }
    }

    .outer9 {
      z-index: auto;
      width: 252px;
      height: 22px;
      margin: 5px 0 0 817px;

      .info15 {
        z-index: 267;
        width: 252px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 13px;
        letter-spacing: 0.9750000238418579px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 22px;
        text-align: left;
      }
    }
  }

  .outer5 {
    z-index: auto;
    width: 144px;
    height: 25px;
    margin: 95px 0 0 817px;

    .word21 {
      z-index: 262;
      width: 144px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.85);
      font-size: 18px;
      font-family: PingFangSC-Semibold;
      white-space: nowrap;
      line-height: 25px;
      text-align: left;
    }
  }

  .word21 {
    z-index: 262;
    width: 144px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.85);
    font-size: 18px;
    font-family: PingFangSC-Semibold;
    white-space: nowrap;
    line-height: 25px;
    text-align: left;
  }

  .outer6 {
    z-index: auto;
    width: 1164px;
    height: 103px;
    margin: 15px 0 0 560px;

    .info13 {
      z-index: 254;
      width: 54px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(197, 197, 197, 1);
      font-size: 53px;
      white-space: nowrap;
      line-height: 74px;
      text-align: left;
      margin-top: 8px;
    }

    .outer8 {
      z-index: auto;
      width: 308px;
      height: 103px;
      margin-left: 15px;

      .word22 {
        z-index: 263;
        width: 308px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 13px;
        letter-spacing: 0.9750000238418579px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 22px;
        text-align: left;
      }

      .info14 {
        z-index: 264;
        width: 168px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 13px;
        letter-spacing: 0.9750000238418579px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 22px;
        text-align: left;
        align-self: flex-start;
        margin-top: 5px;
      }

      .word23 {
        z-index: 265;
        width: 224px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 13px;
        letter-spacing: 0.9750000238418579px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 22px;
        text-align: left;
        align-self: flex-start;
        margin-top: 5px;
      }

      .txt8 {
        z-index: 266;
        width: 112px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 13px;
        letter-spacing: 0.9750000238418579px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 22px;
        text-align: left;
        align-self: flex-start;
        margin-top: 5px;
      }
    }

    .word24 {
      z-index: 294;
      width: 36px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
      margin: 37px 0 0 547px;
    }
  }

  .info13 {
    z-index: 254;
    width: 54px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(197, 197, 197, 1);
    font-size: 53px;
    white-space: nowrap;
    line-height: 74px;
    text-align: left;
    margin-top: 8px;
  }

  .icon10 {
    z-index: 260;
    width: 27px;
    height: 28px;
  }

  .outer8 {
    z-index: auto;
    width: 308px;
    height: 103px;
    margin-left: 15px;

    .word22 {
      z-index: 263;
      width: 308px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: 13px;
      letter-spacing: 0.9750000238418579px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 22px;
      text-align: left;
    }

    .info14 {
      z-index: 264;
      width: 168px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: 13px;
      letter-spacing: 0.9750000238418579px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 22px;
      text-align: left;
      align-self: flex-start;
      margin-top: 5px;
    }

    .word23 {
      z-index: 265;
      width: 224px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: 13px;
      letter-spacing: 0.9750000238418579px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 22px;
      text-align: left;
      align-self: flex-start;
      margin-top: 5px;
    }

    .txt8 {
      z-index: 266;
      width: 112px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: 13px;
      letter-spacing: 0.9750000238418579px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 22px;
      text-align: left;
      align-self: flex-start;
      margin-top: 5px;
    }
  }

  .word22 {
    z-index: 263;
    width: 308px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(153, 153, 153, 1);
    font-size: 13px;
    letter-spacing: 0.9750000238418579px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 22px;
    text-align: left;
  }

  .info14 {
    z-index: 264;
    width: 168px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(153, 153, 153, 1);
    font-size: 13px;
    letter-spacing: 0.9750000238418579px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 22px;
    text-align: left;
    align-self: flex-start;
    margin-top: 5px;
  }

  .word23 {
    z-index: 265;
    width: 224px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(153, 153, 153, 1);
    font-size: 13px;
    letter-spacing: 0.9750000238418579px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 22px;
    text-align: left;
    align-self: flex-start;
    margin-top: 5px;
  }

  .txt8 {
    z-index: 266;
    width: 112px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(153, 153, 153, 1);
    font-size: 13px;
    letter-spacing: 0.9750000238418579px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 22px;
    text-align: left;
    align-self: flex-start;
    margin-top: 5px;
  }

  .word24 {
    z-index: 294;
    width: 36px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
    margin: 37px 0 0 547px;
  }

  .outer9 {
    z-index: auto;
    width: 252px;
    height: 22px;
    margin: 5px 0 0 817px;

    .info15 {
      z-index: 267;
      width: 252px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: 13px;
      letter-spacing: 0.9750000238418579px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 22px;
      text-align: left;
    }
  }

  .info15 {
    z-index: 267;
    width: 252px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(153, 153, 153, 1);
    font-size: 13px;
    letter-spacing: 0.9750000238418579px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 22px;
    text-align: left;
  }

  .layer7 {
    z-index: auto;
    width: 144px;
    height: 25px;
    margin: 95px 0 0 817px;

    .info26 {
      z-index: 273;
      width: 144px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.85);
      font-size: 18px;
      font-family: PingFangSC-Semibold;
      white-space: nowrap;
      line-height: 25px;
      text-align: left;
    }
  }

  .info26 {
    z-index: 273;
    width: 144px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.85);
    font-size: 18px;
    font-family: PingFangSC-Semibold;
    white-space: nowrap;
    line-height: 25px;
    text-align: left;
  }

  .layer8 {
    z-index: auto;
    width: 1639px;
    height: 103px;
    margin: 15px 0 0 85px;

    .section14 {
      z-index: auto;
      width: 191px;
      height: 75px;
      margin-top: 8px;

      .info27 {
        z-index: 291;
        width: 104px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.85);
        font-size: 26px;
        font-family: PingFangSC-Semibold;
        white-space: nowrap;
        line-height: 37px;
        text-align: left;
        align-self: center;
      }

      .word35 {
        z-index: 292;
        width: 191px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.5);
        font-size: 20px;
        white-space: nowrap;
        line-height: 28px;
        text-align: left;
        margin-top: 10px;
      }
    }
  }

  .section14 {
    z-index: auto;
    width: 191px;
    height: 75px;
    margin-top: 8px;

    .info27 {
      z-index: 291;
      width: 104px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.85);
      font-size: 26px;
      font-family: PingFangSC-Semibold;
      white-space: nowrap;
      line-height: 37px;
      text-align: left;
      align-self: center;
    }

    .word35 {
      z-index: 292;
      width: 191px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.5);
      font-size: 20px;
      white-space: nowrap;
      line-height: 28px;
      text-align: left;
      margin-top: 10px;
    }
  }

  .info27 {
    z-index: 291;
    width: 104px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.85);
    font-size: 26px;
    font-family: PingFangSC-Semibold;
    white-space: nowrap;
    line-height: 37px;
    text-align: left;
    align-self: center;
  }

  .word35 {
    z-index: 292;
    width: 191px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.5);
    font-size: 20px;
    white-space: nowrap;
    line-height: 28px;
    text-align: left;
    margin-top: 10px;
  }

  .layer9 {
    z-index: auto;
    width: 252px;
    height: 22px;
    margin: 5px 0 0 817px;

    .word40 {
      z-index: 278;
      width: 252px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: 13px;
      letter-spacing: 0.9750000238418579px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 22px;
      text-align: left;
    }
  }

  .word40 {
    z-index: 278;
    width: 252px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(153, 153, 153, 1);
    font-size: 13px;
    letter-spacing: 0.9750000238418579px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 22px;
    text-align: left;
  }

  .section11 {
    z-index: 247;
    height: 360px;
    background-color: rgba(248, 248, 248, 1);
    width: 100%;

    .outer10 {
      z-index: auto;
      width: 144px;
      height: 25px;
      margin: 95px 0 0 817px;
    }

    .outer11 {
      z-index: auto;
      width: 1164px;
      height: 103px;
      margin: 15px 0 0 560px;

      .txt9 {
        z-index: 256;
        width: 64px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(197, 197, 197, 1);
        font-size: 53px;
        white-space: nowrap;
        line-height: 74px;
        text-align: left;
        margin-top: 9px;
      }

      .group7 {
        z-index: 281;
        height: 44px;
        border-radius: 50%;
        background: url('../assets/index/img13-1.png'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPng5963796593919d09b823661cc6b683bee3e942bcb5bc35ec494a11da75ab9e2e)
        100% no-repeat;
        margin-left: 134px;
        width: 44px;
        justify-content: center;
        align-items: center;

        .label12 {
          z-index: 282;
          width: 27px;
          height: 28px;
        }
      }

      .group8 {
        z-index: auto;
        width: 308px;
        height: 103px;
        margin-left: 15px;

        .word25 {
          z-index: 285;
          width: 308px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 13px;
          letter-spacing: 0.9750000238418579px;
          font-family: PingFangSC-Medium;
          white-space: nowrap;
          line-height: 22px;
          text-align: left;
        }

        .word26 {
          z-index: 286;
          width: 168px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 13px;
          letter-spacing: 0.9750000238418579px;
          font-family: PingFangSC-Medium;
          white-space: nowrap;
          line-height: 22px;
          text-align: left;
          align-self: flex-start;
          margin-top: 5px;
        }

        .txt10 {
          z-index: 287;
          width: 224px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 13px;
          letter-spacing: 0.9750000238418579px;
          font-family: PingFangSC-Medium;
          white-space: nowrap;
          line-height: 22px;
          text-align: left;
          align-self: flex-start;
          margin-top: 5px;
        }

        .info17 {
          z-index: 288;
          width: 112px;
          display: block;
          overflow-wrap: break-word;
          color: rgba(153, 153, 153, 1);
          font-size: 13px;
          letter-spacing: 0.9750000238418579px;
          font-family: PingFangSC-Medium;
          white-space: nowrap;
          line-height: 22px;
          text-align: left;
          align-self: flex-start;
          margin-top: 5px;
        }
      }

      .txt11 {
        z-index: 304;
        width: 36px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(0, 0, 0, 0.5);
        font-size: 12px;
        white-space: nowrap;
        line-height: 17px;
        text-align: left;
        margin: 37px 0 0 547px;
      }

      .label13 {
        z-index: 305;
        width: 13px;
        height: 13px;
        background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
        100% no-repeat;
        margin: 39px 0 0 3px;
      }
    }

    .outer12 {
      z-index: auto;
      width: 252px;
      height: 22px;
      margin: 5px 0 0 817px;

      .info18 {
        z-index: 289;
        width: 252px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 13px;
        letter-spacing: 0.9750000238418579px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 22px;
        text-align: left;
      }
    }
  }

  .outer11 {
    z-index: auto;
    width: 1164px;
    height: 103px;
    margin: 15px 0 0 560px;

    .txt9 {
      z-index: 256;
      width: 64px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(197, 197, 197, 1);
      font-size: 53px;
      white-space: nowrap;
      line-height: 74px;
      text-align: left;
      margin-top: 9px;
    }

    .group8 {
      z-index: auto;
      width: 308px;
      height: 103px;
      margin-left: 15px;

      .word25 {
        z-index: 285;
        width: 308px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 13px;
        letter-spacing: 0.9750000238418579px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 22px;
        text-align: left;
      }

      .word26 {
        z-index: 286;
        width: 168px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 13px;
        letter-spacing: 0.9750000238418579px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 22px;
        text-align: left;
        align-self: flex-start;
        margin-top: 5px;
      }

      .txt10 {
        z-index: 287;
        width: 224px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 13px;
        letter-spacing: 0.9750000238418579px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 22px;
        text-align: left;
        align-self: flex-start;
        margin-top: 5px;
      }

      .info17 {
        z-index: 288;
        width: 112px;
        display: block;
        overflow-wrap: break-word;
        color: rgba(153, 153, 153, 1);
        font-size: 13px;
        letter-spacing: 0.9750000238418579px;
        font-family: PingFangSC-Medium;
        white-space: nowrap;
        line-height: 22px;
        text-align: left;
        align-self: flex-start;
        margin-top: 5px;
      }
    }

    .txt11 {
      z-index: 304;
      width: 36px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(0, 0, 0, 0.5);
      font-size: 12px;
      white-space: nowrap;
      line-height: 17px;
      text-align: left;
      margin: 37px 0 0 547px;
    }

    .label13 {
      z-index: 305;
      width: 13px;
      height: 13px;
      background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
      100% no-repeat;
      margin: 39px 0 0 3px;
    }
  }

  .txt9 {
    z-index: 256;
    width: 64px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(197, 197, 197, 1);
    font-size: 53px;
    white-space: nowrap;
    line-height: 74px;
    text-align: left;
    margin-top: 9px;
  }

  .label12 {
    z-index: 282;
    width: 27px;
    height: 28px;
  }

  .group8 {
    z-index: auto;
    width: 308px;
    height: 103px;
    margin-left: 15px;

    .word25 {
      z-index: 285;
      width: 308px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: 13px;
      letter-spacing: 0.9750000238418579px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 22px;
      text-align: left;
    }

    .word26 {
      z-index: 286;
      width: 168px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: 13px;
      letter-spacing: 0.9750000238418579px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 22px;
      text-align: left;
      align-self: flex-start;
      margin-top: 5px;
    }

    .txt10 {
      z-index: 287;
      width: 224px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: 13px;
      letter-spacing: 0.9750000238418579px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 22px;
      text-align: left;
      align-self: flex-start;
      margin-top: 5px;
    }

    .info17 {
      z-index: 288;
      width: 112px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: 13px;
      letter-spacing: 0.9750000238418579px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 22px;
      text-align: left;
      align-self: flex-start;
      margin-top: 5px;
    }
  }

  .word25 {
    z-index: 285;
    width: 308px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(153, 153, 153, 1);
    font-size: 13px;
    letter-spacing: 0.9750000238418579px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 22px;
    text-align: left;
  }

  .word26 {
    z-index: 286;
    width: 168px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(153, 153, 153, 1);
    font-size: 13px;
    letter-spacing: 0.9750000238418579px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 22px;
    text-align: left;
    align-self: flex-start;
    margin-top: 5px;
  }

  .txt10 {
    z-index: 287;
    width: 224px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(153, 153, 153, 1);
    font-size: 13px;
    letter-spacing: 0.9750000238418579px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 22px;
    text-align: left;
    align-self: flex-start;
    margin-top: 5px;
  }

  .info17 {
    z-index: 288;
    width: 112px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(153, 153, 153, 1);
    font-size: 13px;
    letter-spacing: 0.9750000238418579px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 22px;
    text-align: left;
    align-self: flex-start;
    margin-top: 5px;
  }

  .txt11 {
    z-index: 304;
    width: 36px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(0, 0, 0, 0.5);
    font-size: 12px;
    white-space: nowrap;
    line-height: 17px;
    text-align: left;
    margin: 37px 0 0 547px;
  }

  .label13 {
    z-index: 305;
    width: 13px;
    height: 13px;
    background: url('https://lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c'); // lanhu.oss-cn-beijing.aliyuncs.com/SketchPngf586a51cf28aaedea32c9db54e499f904014f2eabab98729c66567b3f310b11c)
    100% no-repeat;
    margin: 39px 0 0 3px;
  }

  .outer12 {
    z-index: auto;
    width: 252px;
    height: 22px;
    margin: 5px 0 0 817px;

    .info18 {
      z-index: 289;
      width: 252px;
      display: block;
      overflow-wrap: break-word;
      color: rgba(153, 153, 153, 1);
      font-size: 13px;
      letter-spacing: 0.9750000238418579px;
      font-family: PingFangSC-Medium;
      white-space: nowrap;
      line-height: 22px;
      text-align: left;
    }
  }

  .info18 {
    z-index: 289;
    width: 252px;
    display: block;
    overflow-wrap: break-word;
    color: rgba(153, 153, 153, 1);
    font-size: 13px;
    letter-spacing: 0.9750000238418579px;
    font-family: PingFangSC-Medium;
    white-space: nowrap;
    line-height: 22px;
    text-align: left;
  }
</style>
