import request from '@/utils/request'
import CONSTANT from '../../config/global.js'

export function getBannerList(params){
	return request({
	  url: CONSTANT.TwoHttp +'/HomePageBanner/officialWebsite/getPublishList',
	  method: 'get',
	  params
	})
}
