<template>
  <div class="top-swiper flex-col jus-center ali-center">
    <swiper
      id="myswiper"
      ref="mySwiper"
      :options="swiperOptions"
      @slideChange="onSlideChange"
      :pagination="{ clickable: false }"
      @mouseover.native="mouseover"
      @mouseout.native="mouseout"
    >
      <swiper-slide
        v-for="(item, index) in bannerArr"
        :key="index"
        :data-swiper-autoplay="videosAllTime"
      >
        <video
          class="video"
          data-keepplaying
          :src="item"
          :controls="false"
          width="100%"
          height="100%"
          :loop="false"
          muted
          autoplay
        ></video>
      </swiper-slide>
      <!-- <div class="swiper-pagination" slot="pagination"></div> -->
      <!-- <div class="swiper-button-prev" slot="button-prev" @click="prev"></div> -->
      <!-- <div class="swiper-button-next" slot="button-next" @click="next"></div> -->
    </swiper>

    <div
      class="swiper-button-prev"
      slot="button-prev"
      @click="prev"
      id="button-prev"
      @mouseover="mouseover"
      @mouseout="mouseout"
    ></div>
    <div
      class="swiper-button-next"
      slot="button-next"
      @click="next"
      id="button-next"
      @mouseover="mouseover"
      @mouseout="mouseout"
    ></div>

    <div class="threeCores flex-col">
      <div class="title commonTitle">3大核心</div>
      <!-- <div class="sTitle">3 CORES</div> -->
      <div class="content flex-row jus-between">
        <div
          v-for="(item, index) in coresArr"
          :key="index"
          class="flex-col jus-center ali-center"
        >
          <img :src="item.img" alt="" srcset="" />
          <div class="name">{{ item.name }}</div>
        </div>
      </div>
    </div>

    <div class="botnav">
      <div v-for="(item, index) in elnum" :key="index" @click="navclick(index)">
        <el-progress
          :class="item.name ? 'elporg' : 'elpor'"
          :percentage="item.num"
          :text-inside="true"
          color="#478fd6ff"
        ></el-progress>
      </div>
    </div>
  </div>
</template>

<script>
import { getBannerList } from "@/api/TopSwiper/TopSwiper.js";
export default {
  name: "TopSwiper",
  data() {
    return {
      dividend: 0,
      // elnum:[1,10,50,1],
      elnum: [
        {
          num: 0,
          name: true,
        },
        {
          num: 0,
          name: false,
        },
        {
          num: 0,
          name: false,
        },
        {
          num: 0,
          name: false,
        },
      ],
      coresArr: [
        {
          img: require("@/assets/index/cores1.png"),
          name: "健康知识科普教育",
        },
        {
          img: require("@/assets/index/cores2.png"),
          name: "健康状况监测评估",
        },
        {
          img: require("@/assets/index/cores3.png"),
          name: "健康风险干预管理",
        },
      ],
      bannerArr: [
        // 'https://www.runoob.com/try/demo_source/mov_bbb.mp4'
        // "http://www.hbjyjh.com/video/video1.mp4",
      ],
      videoArr: [
        // 'http://r3j1ao6o1.hn-bkt.clouddn.com/%E4%B9%9D%E5%9F%9F%E8%81%9A%E5%90%88%E4%B8%89%E5%A4%A7%E6%A0%B8%E5%BF%83%EF%BC%88%E7%AC%AC1%E6%9D%A1%E8%A7%86%E9%A2%91%C2%B7%E6%97%A0%E5%A3%B0%E7%89%88%EF%BC%89.mp4',
        // "http://www.hbjyjh.com/video/video2.mp4",
        // "http://www.hbjyjh.com/video/video3.mp4",
        // "http://www.hbjyjh.com/video/video4.mp4",
      ],
      swiperOptions: {
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
        //    autoplay: {
        //         disableOnInteraction: false,

        //     },
        // pagination: {
        //            el:'.swiper-pagination',
        // 		clickable: true,
        // 		 // type: 'custom',
        // 		  // renderCustom: function (swiper, current, total) {
        // 		  //          return  '<div class="botnav"><div class="bot"> </div></div>'
        // 		  //        }
        //          },
      },
      activeIndex: -1,
      flag: -1,
      videosAllTime: 15700,
    };
  },
  watch: {
    flag() {
      if (this.flag < this.videoArr.length) {
        this.bannerArr.push(this.videoArr[this.flag]);
        this.elnum[this.flag + 1].name = true;
      } else {
        clearInterval(this.flagInterval);
      }
    },
       activeIndex(newdata,olddata){
    	     let videos=document.getElementsByTagName('video')
    	    if(olddata==-1){
    			  // videos[newdata].play()
    			  this.adsdf=setInterval(()=>{
    			   		this.elnum[newdata].num=parseInt((videos[newdata].currentTime/(videos[newdata].duration/100)).toFixed(2))
    			   	},300)
    		}else{
    	       clearInterval(this.adsdf)
    			this.elnum[olddata].num=0
    			this.videosAllTime=parseInt((videos[newdata].duration*1000).toFixed())
    			console.log(this.videosAllTime)
    			this.adsdf=setInterval(()=>{
    			videos[olddata].currentTime=0
    			// videos[olddata].pause()
    			// videos[newdata].play()
    			this.elnum[newdata].num=parseInt((videos[newdata].currentTime/(videos[newdata].duration/100)).toFixed(2))
    				    	},300)
    		}

       }
  },
  mounted() {
    this.flagInterval = setInterval(() => {
      this.flag += 1;
    }, 3000);
  },
  created() {
    this.getBanner();

    setTimeout(() => {
      this.activeIndex = 0;
    }, 100);
  },
  destroyed() {
    clearInterval(this.adsdf);
  },
  methods: {
    getBanner() {
      var a = {
        subsystemIdentificationCode: "1E1FF846F78548E6AC29076215F10CDA",
      };
      getBannerList(a).then((res) => {
        let videolist = res.data.map(({ videoPath }) => ({ videoPath }));
        videolist.forEach((m) => {
          m.videoPath = "https://fileqn.hbjyjh.com/" + m.videoPath;
        });
        videolist = videolist.map((ele) => ele.videoPath);
        this.bannerArr[0] = videolist[0];
        this.videoArr = videolist.slice(1);
        this.elnum = videolist.map((m, index) => {
          return {
            num: 0,
            name: index === 0,
          };
        });
        console.log("-----111", this.bannerArr, this.videoArr);
      });
    },

    prev() {
      //   this.$refs.mySwiper.$swiper.slidePrev();
    },

    next() {
      //   this.$refs.mySwiper.$swiper.slideNext();
    },
    mouseover() {
      // console.log("--111---");
      document.getElementById("button-prev").style.display = "block";
      document.getElementById("button-next").style.display = "block";
    },
    mouseout() {
      // console.log("-----");
      document.getElementById("button-prev").style.display = "none";
      document.getElementById("button-next").style.display = "none";
    },
    onSlideChange() {
      this.activeIndex = this.$refs.mySwiper.$swiper.activeIndex;
    },
    navclick(index) {
      this.$refs.mySwiper.$swiper.slideTo(index, 500, true);
    },
    // firstVideo(){
    // 	 let videos
    // 	 videos=document.getElementsByTagName('video')
    // this.aser=setInterval(()=>{
    // 		this.elnum[0].num=parseInt((videos[1].currentTime/(videos[1].duration/100)).toFixed(2))

    // 	},300)
    // }
  },

  computed: {
    // dividend(){
    // 	console.log("走")
    // let videtime=document.getElementsByClassName('video')
    // 	let all=videtime[this.activeIndex-1].duration
    // 	let at=videtime[this.activeIndex-1].currentTime
    // 	let chushu=all/100
    // 	console.log(Math.round(at/chushu))
    // 	// return Math.round(at/chushu)
    // 	return at
    // }
  },
};
</script>

<style lang="stylus" scoped >
.title {
  font-size: 26px;
  font-family: PingFangSC-Semibold, PingFang SC;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
  line-height: 37px;
  margin-bottom: 50px;
}

.sTitle {
  font-size: 20px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: rgba(0, 0, 0, 0.5);
  line-height: 28px;
  margin-bottom: 20px;
}

.top-swiper {
  height: 100%;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.swiper, .swiper-slide, .swiper-container {
  height: 600px;
  width: 100%;

  .video-player {
    width: 100%;
    height: 600px;
  }

  img {
    width: 100%;
    height: 100%;
  }

  .divBgc {
    width: 100%;
    height: 100%;
  }
}

.threeCores {
  width: 100%;
  align-items: center;
  justify-content: center;
  flex: 1;

  img {
    display: block;
    width: 100px;
    height: 100px;
  }

  .content {
    width: 786px;

    .name {
      font-size: 20px;
      font-family: PingFangSC-Regular, PingFang SC;
      font-weight: 400;
      color: rgba(0, 0, 0, 0.85);
      line-height: 28px;
      margin-top: 14px;
    }
  }
}
</style>
<style lang="less" >
/* .swiper-pagination{
	  height: 40px;
	  display: flex;
	  flex-direction: row;
	  align-items: center;
	  justify-content: center;
	} */
.botnav {
  // width: 240px;
  height: 5px;
  display: flex;
  position: absolute;
  z-index: 10;
  top: 520px;
  // left: 830px;
  .el-progress-bar__outer {
    background-color: rgba(255, 255, 255, 0.4);
  }
}
.botnav > div:hover {
  cursor: pointer;
}
.botnav > div {
  height: 70px;
}
.elpor {
  opacity: 0;
}
.elporg {
  width: 55px;
  height: 4px;
  margin-right: 5px;
  opacity: 1;
  margin-top: 15px;
}
// /deep/.el-progress>.el-progress-bar>.el-progress-bar__outer>.el-progress-bar__inner>.el-progress-bar__innerText{
// 	background-color: rgb(0,0,0,0.2);
// }
// /deep/

.swiper-button-next,
.swiper-button-prev {
  top: 0 !important;
  margin-top: 300px;
  transform: translateY(-50%);
}

.swiper-button-prev:after {
  display: none;
}
.swiper-button-next:after {
  display: none;
}

/*再自定义样式*/
.swiper-button-prev {
  width: 50px;
  height: 50px;
  background: url("../assets/icon_arrow03.png") no-repeat;
  bottom: 15px;
  margin-left: 30px;
}
.swiper-button-next {
  width: 50px;
  height: 50px;
  background: url("../assets/icon_arrow04.png") no-repeat;
  bottom: 15px;
  margin-right: 30px;
}
</style>
